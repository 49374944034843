(function() {

    var __slice = [].slice;

    this.Uploader = function() {

        var defaultOptions = {
            element: null,  //QuerySelector Ex: '.file' or "#file'
            maxFilesize: 2048,  //In Ko!
            maxFiles: 1,
            acceptedFiles: null,    //Ex: [".doc", ".docx", ".pdf"]
            inputId: 'file-uploader',
            inputName: 'file',
            alertTime: 5000,    //In ms
            required: false,    //Bool
            style: 'list',

            containerHeight: "300px",
            containerWidth: "auto",

            textButtonMessage: "Select a file",
            textFallbackButtonMessage: 'Browse',
            textDefaultMessage: "Drop files here to upload",
            textFallbackMessage: "Your browser does not support drag'n'drop file uploads.",
            textFileTooBig: "File is too big.",
            textFileAdded: "File added",
            textInvalidFileType: "You can't upload files of this type.",
            textRemoveFile: "Remove file",
            textMaxFilesSelected: "You can only upload a maximum of 1 file",
            textMaxFilesExceeded: "You can not upload any more files.",

            addedfile: function(file) {
                return true;
            },
            dragend: function(e) {
                return true;
            },
            dragenter: function(e) {
                return true;
            },
            dragover: function(e) {
                return true;
            },
            dragleave: function(e) {
                return true;
            },
            drop: function(e) {
                return true;
            },
            error: function(file, message) {
                return true;
            },
            init: function(el) {
                return true;
            },
            initialized: function(el) {
                return true;
            },
            processing: function(file) {
                return true;
            },
            removedfile: function(file) {
                return true;
            },
            reset: function() {
                return this.element.classList.remove("dz-started");
            },
            thumbnail: function(file) {
                return true;
            },
            notSupported: function(el) {
                return true;
            }
        };

        if (arguments[0] && typeof arguments[0] === "object") {
            this.options = extendDefaults(defaultOptions, arguments[0]);
        }

        this.init();

    };

    this.Uploader.prototype.addEventListener = this.Uploader.prototype.on;

    this.Uploader.prototype.init = function() {
        var _el;

        this.files = [];
        this.supported = browserSupport();

        _el = this.options.element;

        if (_el === null || _el === undefined || typeof _el !== 'string'){
            console.log('Element option must be declared');
            return false;
        }

        this.el = document.querySelector(_el);
        this.options.init(this.el);

        if (this.el) {
            this.removeInputs();
            if (this.supported) {
                this.setContainer();
                this.setLabel();
                this.setDropzone();
            } else {
                this.setFallbackLabel();
            }
            this.setFileInput(true);
            if (!this.supported) {
                this.options.notSupported(this.el);
            }
            this.options.initialized(this.el);
        }
    };

    this.Uploader.prototype.setContainer = function() {
        var container__className = "uploader--container";

        if (this.fileContainer) {
            this.el.removeChild(this.fileContainer);
        }
        this.fileContainer = document.createElement("div");
        this.fileContainer.className = container__className;
        this.fileContainer.style.position = "relative";
        this.fileContainer.style.height = this.options.containerHeight;
        this.fileContainer.style.width = this.options.containerWidth;
        this.fileContainer.innerHTML = "<div class=\"uploader--thumbnails-container\"><div class=\"uploader--thumbnails-list\"></div></div>";
        this.el.appendChild(this.fileContainer);
        return this.setAlert();

    };

    this.Uploader.prototype.setAlert = function () {
        var alertContainer__className = "uploader--alert-container";
        var alert__className = "uploader--alert";
        if (this.alertContainer) {
            this.fileContainer.removeChild(this.alertContainer);
        }
        this.alertContainer = document.createElement("div");
        this.alertContainer.className = alertContainer__className;
        this.alertContainer.innerHTML = "<span id=\" " + alert__className + "\"></span>";
        this.fileContainer.prepend(this.alertContainer);
    };

    this.Uploader.prototype.removeInputs = function () {
        var inputs =  this.el.querySelectorAll('input[type="file"]');
        if (inputs) {
            for (var _i = 0; _i < inputs.length; _i++) {
                inputs[_i].parentNode.removeChild(inputs[_i]);
            }
        }
    };

    this.Uploader.prototype.setDropzone = function() {
        var _this = this;
        var dropzone__className = "uploader--dropzone uploader--default-message-container";

        if (this.fileDropzone) {
            this.fileContainer.removeChild(this.fileDropzone);
        }
        this.fileDropzone = document.createElement("div");
        this.fileDropzone.className = dropzone__className;
        this.fileDropzone.innerHTML = "<span class=\"uploader--default-message\">" + this.options.textDefaultMessage + "</span>";
        this.fileContainer.appendChild(this.fileDropzone);

        this.fileDropzone.addEventListener("dragover", function(e) {
            e.preventDefault();
            _this.fileDropzone.classList.add('uploader--dropzone-hover');
            _this.options.dragover(e);
        }, false);

        this.fileDropzone.addEventListener("dragenter", function(e) {
            e.preventDefault();
            _this.fileDropzone.classList.add('uploader--dropzone-hover');
            _this.options.dragenter(e);
        }, false);

        this.fileDropzone.addEventListener("dragend", function(e) {
            e.preventDefault();
            _this.fileDropzone.classList.remove('uploader--dropzone-hover');
            _this.options.dragend(e);
        }, false);

        this.fileDropzone.addEventListener("dragleave", function(e) {
            e.preventDefault();
            _this.fileDropzone.classList.remove('uploader--dropzone-hover');
            _this.options.dragleave(e);
        }, false);

        this.fileDropzone.addEventListener("dragdrop", function(e) {
            e.preventDefault();
            _this.fileDropzone.classList.remove('uploader--dropzone-hover');
            _this.options.drop(e);
            _this.drop(e);
        }, false);

        this.fileDropzone.addEventListener("drop", function(e) {
            e.preventDefault();
            _this.fileDropzone.classList.remove('uploader--dropzone-hover');
            _this.options.drop(e);
            _this.drop(e);
        }, false);
    };

    this.Uploader.prototype.setFileInput = function(setEvent) {
        var input__className = "uploader--hidden-input";
        if (this.fileInput) {
            this.el.removeChild(this.fileInput);
        }
        this.fileInput = document.createElement("input");
        this.fileInput.setAttribute("type", "file");
        if ((this.options.maxFiles !== null) && Number.isInteger(this.options.maxFiles) && this.options.maxFiles > 1) {
            this.fileInput.multiple = true;
        }
        this.fileInput.className = input__className;
        if (this.options.inputId !== null && typeof this.options.inputId === 'string') {
            this.fileInput.id = this.options.inputId;
        }
        if (this.options.acceptedFiles !== null && Array.isArray(this.options.acceptedFiles)) {
            this.fileInput.setAttribute("accept", this.options.acceptedFiles.join(', '));
        }
        if (this.options.inputName !== null && typeof this.options.inputName === 'string') {
            var name = this.options.inputName;
            if ((this.options.maxFiles !== null) && Number.isInteger(this.options.maxFiles) && this.options.maxFiles > 1) {
                name += '[]';
            }
            this.fileInput.setAttribute("name", name);
        }
        if (this.options.required) {
            this.fileInput.setAttribute('required','required');
        }
        if (this.supported) {
            this.fileInput.style.visibility = "hidden";
            this.fileInput.style.position = "relative";
            this.fileInput.style.top = "0";
            this.fileInput.style.left = "0";
            this.fileInput.style.height = "0";
            this.fileInput.style.width = "0";
        }

        this.el.appendChild(this.fileInput);

        return setEvent ? this.setEventListener() : true;
    };

    this.Uploader.prototype.setEventListener = function() {
        var _this = this;
        return (this.supported) ?
            this.fileInput.addEventListener("change", this.inputChange.bind(this), false):
            this.fileInput.addEventListener("change", function() {
                _this.options.addedfile(_this.fileInput.files);
            }, false);
    };

    this.Uploader.prototype.setLabel = function() {
        var label_container__className = "uploader--button-container";
        var label__className = "uploader--button";
        var label__id = "uploader--button";

        if (this.labelContainer) {
            this.el.removeChild(this.labelContainer);
        }

        this.labelContainer = document.createElement("div");
        this.labelContainer.className = label_container__className;

        this.labelInput = document.createElement("label");
        this.labelInput.setAttribute('for', this.options.inputId );
        this.labelInput.id = label__id;
        this.labelInput.className = label__className;
        this.labelInput.innerHTML = this.options.textButtonMessage;

        this.labelContainer.appendChild(this.labelInput);
        this.fileContainer.appendChild(this.labelContainer);
    };

    this.Uploader.prototype.setFallbackLabel = function() {
        this.labelFallBackInput = document.createElement("label");
        this.labelFallBackInput.setAttribute('for', this.options.inputId );
        this.labelFallBackInput.innerHTML = this.options.textFallbackButtonMessage;

        this.el.appendChild(this.labelFallBackInput);
    };

    this.Uploader.prototype.inputChange = function(e) {
        var files = this.fileInput.files;
        if (files.length > 0) {
            this.addFile(files, e);
        }
    };

    this.Uploader.prototype.drop = function(e) {
        var files;
        if (!e.dataTransfer) {
            return false;
        }
        files = e.dataTransfer.files;
        if (files.length > 0) {
            this.addFile(files, e);
        }
    };

    this.Uploader.prototype.addFile = function(files, e) {
        var _i, file;
        for (_i = 0; _i < files.length; _i++) {
            file = files[_i];
            if ((!this.updateMaxFilesReached()) && this.checkFileType(file) && this.checkFileSize(file)) {
                file.url = this.getImageAsDataURL(_i, e);
                this.files.push(file);
                this.updateFiles();
                this.showAlert('success', this.options.textFileAdded);
                this.options.addedfile(file);
            }
        }
    };

    this.Uploader.prototype.removeFile = function(index) {
        var file = this.files[index];
        this.files.splice(index, 1);
        this.updateFiles();
        this.options.removedfile(file);
    };

    this.Uploader.prototype.updateFiles = function() {
        var _i;
        var dataTransfer = new DataTransfer;
        var files = this.files;

        this.fileInput.removeEventListener("change", this.inputChange.bind(this), false);

        this.setFileInput(false);
        for (_i = 0; _i < files.length; _i++) {
            dataTransfer.items.add(files[_i]);
        }
        this.fileInput.value = '';
        this.fileInput.files = dataTransfer.files;

        this.fileInput.addEventListener("change", this.inputChange(e).bind(this), false);

        return this.updateThumbnails();
    };

    this.Uploader.prototype.updateThumbnails = function() {
        var _i, _this, thumbnail,file, name, thumbnails_list, remove_button, documentFragment, thumbnails_list_element, files;
        _this = this;
        documentFragment = document.createDocumentFragment();
        thumbnails_list_element = this.fileContainer.querySelector('.uploader--thumbnails-list');
        files = this.fileInput.files;

        thumbnails_list = document.createElement("div");
        switch (this.options.style) {
            case 'list' :
                thumbnails_list.className = "uploader--thumbnails-list";
                break;
            case 'gallery' :
                thumbnails_list.className = "uploader--thumbnails-gallery";
                break;
            default:
                thumbnails_list.className = "uploader--thumbnails-list";
                break;
        }

        for (_i = 0; _i < files.length; _i++) {

            file = files[_i];

            name = file.name.length >= 40 ? file.name.substring(0, 40) + '...' : file.name;
            this.getImageAsDataURL(file);
            thumbnail = document.createElement("div");
            thumbnail.className = "uploader--thumbnail";
            switch (this.options.style) {
                case 'list' :
                    thumbnail.innerHTML = "<div class=\"uploader--thumbnail-info\"><span class=\"uploader--thumbnail-name\">" + name + "</span><span class=\"uploader--thumbnail-size\">" + this.filesize(file.size) + "</span></div>";
                    break;
                case 'gallery' :
                    thumbnail.innerHTML = "<div class=\"uploader--thumbnail-info\"><span class=\"uploader--thumbnail-name\">" + name + "</span><span class=\"uploader--thumbnail-size\">" + this.filesize(file.size) + "</span></div>";
                    break;
                default:
                    thumbnail.innerHTML = "<div class=\"uploader--thumbnail-info\"><span class=\"uploader--thumbnail-name\">" + name + "</span><span class=\"uploader--thumbnail-size\">" + this.filesize(file.size) + "</span></div>";
                    break;
            }


            remove_button = document.createElement("button");
            remove_button.className = "uploader--thumbnail-remove";
            remove_button.dataset.id = _i;
            remove_button.innerHTML = this.options.textRemoveFile;
            remove_button.addEventListener('click', function (e) {
                e.preventDefault();
                _this.removeFile(this.dataset.id);
            },{once: true});

            thumbnail.appendChild(remove_button);
            this.options.thumbnail(thumbnail);
            thumbnails_list.appendChild(thumbnail);
        }

        documentFragment.appendChild(thumbnails_list);
        this.showMessage();
        this.fileContainer.querySelector('.uploader--thumbnails-container').replaceChild(documentFragment, thumbnails_list_element);

        return this.updateMaxFilesReached();
    };

    this.Uploader.prototype.getImageAsDataURL = function(_i, e) {
        return URL.createObjectURL(e.target.files[_i]);
    };

    this.Uploader.prototype.showMessage = function() {
        var el = this.fileDropzone.querySelector('.uploader--default-message');
        this.fileInput.files.length > 0 ?
            el.classList.add('uploader--hidden'):
            el.classList.remove('uploader--hidden');
    };

    this.Uploader.prototype.updateMaxFilesReached = function() {
        return ((this.options.maxFiles !== null) && this.files.length >= this.options.maxFiles) ? this.maxFilesReached(): this.maxFilesUnreached();
    };

    this.Uploader.prototype.maxFilesReached = function() {
        this.fileContainer.querySelector('.uploader--button-container').classList.add('hidden');
        this.showAlert('error', this.options.textMaxFilesExceeded);
        return true;
    };

    this.Uploader.prototype.maxFilesUnreached = function() {
        this.fileContainer.querySelector('.uploader--button-container').classList.remove('hidden');
        return false;
    };

    this.Uploader.prototype.showAlert = function(type, message){
        var alert__className = "uploader--alert" + " " + type;

        if (this.alertTimer) {
            clearTimeout(this.alertTimer);
        }

        if (this.alert) {
            this.alertContainer.removeChild(this.alert);
        }
        this.alert = document.createElement("div");
        this.alert.className = alert__className;
        this.alert.innerHTML = message;
        this.alertContainer.appendChild(this.alert);
        this.alertTimer = setTimeout(function (_this) {
            _this.alertContainer.removeChild(_this.alert);
            _this.alert = false;
        }, this.options.alertTime, this);
    };

    this.Uploader.prototype.filesize = function(size) {
        var thresh = 1000;
        if (Math.abs(size) < thresh) {
            return size + ' B';
        }
        var units = ['kB','MB','GB','TB','PB','EB','ZB','YB'];
        var u = -1;
        do {
            size /= thresh;
            ++u;
        } while (Math.abs(size) >= thresh && u < units.length - 1);
        return size.toFixed(1)+' '+units[u];
    };

    this.Uploader.prototype.checkFileType = function(file) {
        var fname = file.name;
        var ext = fname.slice((fname.lastIndexOf(".") - 1 >>> 0) + 2);
        if (this.options.acceptedFiles.indexOf('.' + ext) > -1) {
            return true
        } else {
            this.showAlert('error', this.options.textInvalidFileType);
            return false;
        }
    };

    this.Uploader.prototype.checkFileSize = function(file) {
        if (file.size < (Number.parseInt(this.options.maxFilesize)*1000)) {
            return true;
        } else {
            this.showAlert('error', this.options.textFileTooBig);
            return false;
        }
    };

    function browserSupport() {
        // Opera 8.0+
        var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        // Firefox 1.0+
        var isFirefox = typeof InstallTrigger !== 'undefined';
        // Safari 3.0+ "[object HTMLElementConstructor]"
        var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);
        // Internet Explorer 6-11
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        // Edge 20+
        var isEdge = !isIE && !!window.StyleMedia;
        // Chrome 1+
        var isChrome = !!window.chrome && !!window.chrome.webstore;
        // Blink engine detection
        var isBlink = (isChrome || isOpera) && !!window.CSS;

        return isOpera || isChrome || isFirefox;
    }

    function extendDefaults(source, properties) {
        for (var property in properties) {
            if (properties.hasOwnProperty(property) && source.hasOwnProperty(property)) {
                source[property] = properties[property];
            }
        }
        return source;
    }

    // Internet Explorer: Number.isInteger not defined.
    Number.isInteger = Number.isInteger || function(value) {
        return typeof value === "number" &&
            isFinite(value) &&
            Math.floor(value) === value;
    };

}());
