require('../libs/BBW-uploader');

window.addEventListener('load', () => {
    document.querySelectorAll('.delete-page').forEach(element => {
        element.addEventListener('click', e => {
            e.preventDefault();
            let id = element.dataset.id;
            fetchDeletePage(id);
        })
    });

    /*var uploader1 = new Uploader({
        element: '#page_bannerImage_container',
        maxFiles: 1,
        inputId: 'page_bannerImage',
        inputName: 'page[bannerImage]',
        acceptedFiles: [".png", ".jpg", ".PNG", ".JPG"],
        required: true,
        alertTime: 1000,
        addedfile: function(files){
            console.log(files);
        }
    });

    var uploader2 = new Uploader({
        element: '#page_footerImages_container',
        maxFiles: 4,
        inputId: 'page_footerImages',
        inputName: 'page[footerImages]',
        acceptedFiles: [".png", ".jpg", ".PNG", ".JPG"],
        required: true,
        alertTime: 1000,
        addedfile: function(files){
            console.log(files);
        }
    });*/
});

/**
 * Fetch the controller to remove a page.
 * @param id
 */
const fetchDeletePage = (id) => {
    const url = `/admin/pages/remove/${id}`;

    fetch(url)
        .then(response => {
            return response.json();
        })
        .then(data => {
            data.success ?
                deletePageElement(id) :
                console.log('Error: ', data.error);

            setMessage(data.message);
        })
};

/**
 * Remove a page on the index page.
 * @param id
 */
const deletePageElement = (id) => {
    const element = document.querySelector(`[data-id="${id}"]`);
    element.style.opacity = 'none';
    element.parentNode.removeChild(element);
};

/**
 * Show a message
 * @param message
 */
const setMessage = message => {
    const messageContainer = document.getElementById('pageMessage');
    messageContainer.innerHTML = message;
    setTimeout(() => {
        messageContainer.style.display = 'none';
    }, 3000);
    messageContainer.style.display = 'block';
};