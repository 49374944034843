import Sortable from "sortablejs";

const works__selector = document.getElementById('work');
const works__list = document.getElementById('works_list');
const add__work = document.getElementById('addWork');

class Work {
    constructor(id, title) {
        this.id = id;
        this.name = title;
    }
}

Sortable.create(works__list, {
    group: 'foo',
    animation: 100,

    onEnd: () => {
        workHandler.setOrderValue();
    }
});

let workHandler = {
    count: works__list.querySelectorAll('li').length,
    addWork: () => {
        if (workHandler.count < 6){
            let work = new Work(
                works__selector.value,
                works__selector.options[works__selector.options.selectedIndex].dataset.name
            );

            works__list.appendChild(workHandler.createWork(work));
            workHandler.setOrderValue();

            workHandler.count++;

            if (workHandler.count >= 6) {
                add__work.style.display = 'none';
            }
        }
    },

    removeWork: function(id) {
        console.log(works__list);
        works__list.querySelector(`li[data-id="${id}"]`).remove();
        workHandler.count--;
        if (workHandler.count < 6) {
            add__work.style.display = 'block';
        }
    },

    createWork: work => {
        let button = document.createElement('button');
        button.innerHTML = `Remove <img src="${trashIconHtml}" alt="trash icon">`;
        button.className = 'btn btn-icon btn-delete sort_order_remove';
        button.name = 'remove_' + work.id;
        button.type = 'button';
        button.dataset.id = work.id;

        let div = document.createElement('div');
        div.className = 'draganddrop';

        let span = document.createElement('span');
        span.className = 'work_text';
        span.innerText = work.name;

        let input  = document.createElement('input');
        input.name = "category[work_order][]";
        input.type = 'hidden';

        let li = document.createElement('li');
        li.dataset.id = work.id;
        li.className = 'draggable--item';
        li.appendChild(div);
        li.appendChild(input);
        li.appendChild(span);
        li.appendChild(button);

        return li;
    },

    setOrderValue: () => {
        works__list.querySelectorAll('li').forEach(element => {
            element.querySelector('input').value = element.dataset.id;
        })
    }
};

workHandler.setOrderValue();

if (workHandler.count >= 6) {
    add__work.style.display = 'none';
}

$(document).ready(function () {
    $('#addWork').click(function(e){
        e.preventDefault();
        workHandler.addWork();
    });
    $(document).on('click', '.sort_order_remove', function(e){
        e.preventDefault();
        workHandler.removeWork($(this).data('id'));
    });
});