/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/main.css?t=1527078133');
require('../css/themify-icons.css');
require('../css/plugins.css');
require('../css/grid.css');
require('./admin/work.js');
require('./admin/page.js');
require('./admin/request.js');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
//const $ = require('jquery');
//
// global.$ = global.jQuery = $;


const trash_icon = require('../img/icons/trash.svg');
trashIconHtml = `${trash_icon}`;


const imagesContext = require.context('../img', true, /\.(png|jpg|jpeg|gif|ico|svg|webp|webm)$/);
imagesContext.keys().forEach(imagesContext);

//Sentry client for crash/error reporting
var Raven = require('raven-js');
Raven
    .config('https://e26a59bfa9384b1a895494663e388fc2@sentry.bigbadwolf.be/11')
    .install();

// require('./modernizr.js');
const $ = require('./libs/jquery-1.11.2.min.js');
//
// // create global $ and jQuery variables
global.$ = global.jQuery = global.jquery = $;
